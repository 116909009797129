import "./index.css"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "@/templates/layout"

import IconBest from "@/images/icons/icon-best.inline.svg"
import IconCoins from "@/images/icons/icon-coins.inline.svg"
import IconBitvavo from "@/images/icons/icon-bitvavo.inline.svg"
import IconBinance from "@/images/icons/icon-binance.inline.svg"
import IconEtoro from "@/images/icons/icon-etoro.inline.svg"
import IconCoinbase from "@/images/icons/icon-coinbase.inline.svg"
import IconStats from "@/images/icons/icon-stats.inline.svg"

import Step1 from "@/images/steps/step-1.inline.svg"
import Step2 from "@/images/steps/step-2.inline.svg"
import Step3 from "@/images/steps/step-3.inline.svg"

import Chart from "@/images/chart.inline.svg"
import { useTranslation } from "react-i18next"

const schema = JSON.stringify({
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": ["Person", "Organization"],
      "@id": "/#person",
      name: "MadCrypto Admin",
      logo: {
        "@type": "ImageObject",
        "@id": "/#logo",
        url:
          "https://madcrypto.com/wp-content/uploads/2022/05/MadCrypto_dark.png",
        caption: "MadCrypto Admin",
        inLanguage: "en-US",
        width: "2664",
        height: "825",
      },
      image: {
        "@type": "ImageObject",
        "@id": "/#logo",
        url:
          "https://madcrypto.com/wp-content/uploads/2022/05/MadCrypto_dark.png",
        caption: "MadCrypto Admin",
        inLanguage: "en-US",
        width: "2664",
        height: "825",
      },
    },
    {
      "@type": "WebSite",
      "@id": "/#website",
      url: "https://madcrypto.com",
      name: "MadCrypto Admin",
      publisher: { "@id": "/#person" },
      inLanguage: "en-US",
    },
    {
      "@type": "ImageObject",
      "@id":
        "https://madcrypto.com/wp-content/uploads/2022/05/Best-Crypto-Exchange.jpg",
      url:
        "https://madcrypto.com/wp-content/uploads/2022/05/Best-Crypto-Exchange.jpg",
      width: "2000",
      height: "1001",
      caption: "Best Crypto Exchange",
      inLanguage: "en-US",
    },
    {
      "@type": "Person",
      "@id": "/author/matthew_stokes/",
      name: "Matthew Stokes",
      url: "/author/matthew_stokes/",
      image: {
        "@type": "ImageObject",
        "@id":
          "https://madcrypto.com/wp-content/uploads/2022/06/cropped-Matthew-Stokes-scaled-1-96x96.jpg",
        url:
          "https://madcrypto.com/wp-content/uploads/2022/06/cropped-Matthew-Stokes-scaled-1-96x96.jpg",
        caption: "Matthew Stokes",
        inLanguage: "en-US",
      },
      sameAs: ["https://madcrypto.com"],
    },
    {
      "@type": "WebPage",
      "@id": "/staking/#webpage",
      url: "/staking/",
      datePublished: "2022-05-12T20:52:27+00:00",
      dateModified: "2022-07-17T18:02:20+00:00",
      author: { "@id": "/author/matthew_stokes/" },
      isPartOf: { "@id": "/#website" },
      primaryImageOfPage: {
        "@id":
          "https://madcrypto.com/wp-content/uploads/2022/05/Best-Crypto-Exchange.jpg",
      },
      inLanguage: "en-US",
    },
    {
      "@type": "Article",
      author: { "@id": "/author/matthew_stokes/" },
      publisher: { "@id": "/#person" },
      "@id": "/staking/#schema-2886",
      isPartOf: { "@id": "/staking/#webpage" },
      inLanguage: "en-US",
      image: {
        "@id":
          "https://madcrypto.com/wp-content/uploads/2022/05/Best-Crypto-Exchange.jpg",
      },
    },
  ],
})

const HeroUnit = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="container container-small align-center">
        <h1 dangerouslySetInnerHTML={{ __html: t("headline") }} />
      </div>
      <div className="container">
        <HeroMenu />
      </div>
      <Chart className="hero-unit-chart" />
    </>
  )
}

const Index = (props: any) => {
  const { t, i18n } = useTranslation()
  const seo = {
    title: t("seo_title_homepage"),
    description: t("seo_description_homepage"),
    url: "/",
    schema: schema,
    lang: i18n.language,
  }

  return (
    <Layout {...props} seo={seo} className="page-homepage" title="MadCrypto">
      <HeroUnit />
      <div>
        <div className="container container-small align-center">
          <h2 dangerouslySetInnerHTML={{ __html: t("headline2") }} />
          <p className="text-light">{t("paragraph")}</p>
        </div>
        <div className="container">
          <HeroFeatures />
        </div>

        {/*
      <div className="container container-homepage-slider">
        <HeroSlider />
      </div>
      */}
      </div>
    </Layout>
  )
}

export default Index

const HeroFeatures = () => {
  const { t } = useTranslation()
  const icons = [<Step2 />, <Step1 />, <Step3 />]
  const features: {
    image: JSX.Element
    title: string
    description: string
  }[] = (t("features", {
    returnObjects: true,
    defaultValue: [],
  }) as any[]).map((f: any, i: number) => ({
    ...f,
    image: icons[i % icons.length],
  })) as any

  return (
    <div className="hero-features">
      {features.map(({ image, title, description }, i) => (
        <div key={i} className="hero-feature">
          <div className="hero-feature-icon">{image}</div>
          <div className="hero-feature-title">{title}</div>
          <div className="hero-feature-text">{description}</div>
        </div>
      ))}
    </div>
  )
}

const HeroMenu = () => {
  const { t, i18n } = useTranslation()
  let icons: JSX.Element[] = []
  if (i18n.language === "fr_FR") {
    icons = [
      <IconBest />,
      <IconBinance />,
      <IconBitvavo />,
      <IconCoinbase />,
    ]
  } else {
    icons = [
      <IconBest />,
      <IconBinance />,
      <IconBitvavo />,
      <IconEtoro />,
      <IconCoinbase />,
      <IconStats />,
      <IconCoins />,
    ]
  }

  const exchanges: {
    image: JSX.Element
    text: string
    link: string
  }[] = (t("exchanges", {
    returnObjects: true,
    defaultValue: [],
  }) as any[]).map((e: any, i: number) => ({
    ...e,
    image: icons[i % icons.length],
  }))

  return (
    <div className="hero-menu">
      {exchanges.map(({ image, text, link }, i) => (
        <Link key={i} to={link} className="menu-item">
          <div className="menu-icon">{image}</div>
          <div className="menu-text">{text}</div>
        </Link>
      ))}
    </div>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
